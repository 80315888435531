const APIComponent = require('./api_component');

/**
 * A wrapper for the Recent Sales API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class RecentSalesComponent extends APIComponent {
    async getRecentSales() {
        const response = await this.sendRequest(this.config.urls.recent_sales);
        if (response.status === 200) {
            return response.json();
        } else {
            return {
                recent_sales: []
            }
        }
    }
}

module.exports = RecentSalesComponent;
