const APIComponent = require('./api_component');

/**
 * A wrapper for the Freight API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class FreightComponent extends APIComponent {
  /**
   * Get a freight estimate
   * @param {string} suburbUUID The uuid of the suburb
   * @param {boolean} dropship Whether to use dropship rates
   */
  async estimate(suburbUUID = '', dropship = true, weight=null) {
    const url = this.genURL(this.config.urls.api.v1['freight/estimate'], location.origin, 0, { suburb: suburbUUID, dropship, weight });

    const response = await this.sendRequest(url);
    return response.json();
  }

  async suburbs(query = '', country = undefined) {
    if (!country) {
      country = this.config.localStore.countryCode.toUpperCase();
    }
    const url = this.genURL(this.config.urls.api.v1['freight/suburbs'], location.origin, 0, { query, country });

    const response = await this.sendRequest(url);
    return response.json();
  }
}

module.exports = FreightComponent;
