const APIComponent = require('./api_component');

/**
 * A wrapper for the Cart API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class CartComponent extends APIComponent {
    async getCart(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.cart, location.origin, limit);

        const response = await this.sendRequest(url);
        return response.json();
    }

    async updateCart(pk = 0, quantity = 0) {
        const body = {
            quantity: quantity
        };

        const response = await this.sendRequest(this.config.urls.api.v1.cart + pk + "/", body, 'PUT');
        return response;
    }

    async deleteCart(pk = 0) {
        const response = await this.sendRequest(this.config.urls.api.v1.cart + pk + "/", null, 'DELETE');
        return response;
    }

    async selectDiscount(offer = 0, choice = '') {
        const response = await this.sendRequest(this.config.urls.api.v1.icds, {offer: offer, choice: choice});
        return response.json();
    }
}

module.exports = CartComponent;
