const APIComponent = require('./api_component');

/**
 * A wrapper for the Products API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class OrdersComponent extends APIComponent {
    /**
     * Get all products.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.orders, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get a product and its details.
     * @param {string} slug The slug for the product.
     * @param {boolean} offers Enables returning offers for the product.
     */
    async getDetail(uuid = "", offers = false) {
        const url = this.genURL(this.config.urls.api.v1.orders + uuid, location.origin, 0);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get current order
     * @returns {Promise<Order>}
     */
    async getCurrent() {
        const url = this.genURL([this.config.urls.api.v1.orders, 'current'], location.origin, 0);

        let response = await this.sendRequest(url);
        return response.json();
    }

    async overrideOffers(uuid = "", lineItems = []) {
        const url = this.genURL(this.config.urls.api.v1.orders + uuid + '/override_offers/', location.origin, 0);

        let response = await this.sendRequest(url, lineItems, 'POST');
        return response.json();
    }

    async setFreightCharge(uuid = "", amount) {
        amount = Number(amount).toFixed(2);
        const url = this.genURL(this.config.urls.api.v1.orders + uuid + '/', location.origin, 0);

        let response = await this.sendRequest(url, {"freight_charge": amount}, 'PATCH');
        return response.json();
    }

    async splitLine(uuid = "", lineId, newLineQuantity) {
        const url = this.genURL(this.config.urls.api.v1.orders + uuid + '/split_line/', location.origin, 0);

        let response = await this.sendRequest(
            url,
            {
                line_id: lineId,
                new_line_quantity: newLineQuantity,
            },
            'POST');
        return response.json();
    }

    async resetFreightCharge(uuid = "") {
        const url = this.genURL(this.config.urls.api.v1.orders + uuid + '/reset_freight_charge/', location.origin, 0);

        let response = await this.sendRequest(
            url,
            {},
            'POST');
        return response.json();
    }
}

module.exports = OrdersComponent;
