const APIComponent = require('./api_component');

/**
 * A wrapper for the BrandGroups API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class BrandGroupComponent extends APIComponent {
    /**
     * Get brand groups.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.brandgroups, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }
}

module.exports = BrandGroupComponent;
