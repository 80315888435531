const APIComponent = require('./api_component');

/**
 * A wrapper for the Products API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class ProductsComponent extends APIComponent {
    /**
     * Get all products.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0, categorySlug = null) {
        const params = { category: categorySlug };
        const url = this.genURL(this.config.urls.api.v1.discounts, location.origin, limit, params);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get a product and its details.
     * @param {string} slug The slug for the product.
     * @param {boolean} offers Enables returning offers for the product.
     */
    async getDetail(slug = "", offers = false) {
        const url = this.genURL(this.config.urls.api.v1.products + slug, location.origin, 0, {'offers': offers});

        let response = await this.sendRequest(url);
        return response.json();
    }
    async getOrdersForProduct(slug = "",) {
        const url = this.genURL(this.config.urls.api.v1.products + slug + '/orders/', location.origin, 0);

        let response = await this.sendRequest(url);
        return response.json();
    }
    async search(query) {
        const url = this.genURL(this.config.urls.api.v1.products, location.origin, 0, {'search': query});
        let response = await this.sendRequest(url);
        return response.json();
    }
}

module.exports = ProductsComponent;
