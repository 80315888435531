const APIComponent = require('./api_component');

/**
 * A wrapper for the Discounts API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class DiscountsComponent extends APIComponent {
    /**
     * Get discounts (and their products).
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.discounts, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get a discount and its details.
     * @param {string} slug The slug for the discount.
     */
    async getDetail(slug = "") {
        const url = this.genURL(this.config.urls.api.v1.discounts + slug, location.origin);

        let response = await this.sendRequest(url);
        return response.json();
    }
}

module.exports = DiscountsComponent;
