// Lib Imports
import 'bootstrap';

import '../sass/main.scss';

import './browser_support';
import './marquee';
import functions from './functions.legacy';

import htmx from 'htmx.org';


htmx.config.refreshOnHistoryMiss = true;

window.htmx = htmx;

(() => {
    require('htmx.org/dist/ext/alpine-morph')
    require('htmx.org/dist/ext/restored')
    window.marked = require('marked')
})()



window.document.addEventListener('htmx:configRequest', (ev) => {
    ev.detail.headers['X-CSRFToken'] = window.appConfig.csrfToken
})



export { functions };
