const APIComponent = require('./api_component');

/**
 * A wrapper for the Brands API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class BrandsComponent extends APIComponent {
    /**
     * Get brands.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.brands, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }
    async search(query) {
        const url = this.genURL(this.config.urls.api.v1.brands, location.origin, 0, {'name': query});
        let response = await this.sendRequest(url);
        return response.json();
    }
}

module.exports = BrandsComponent;
