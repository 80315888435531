const APIComponent = require('./api_component');

/**
 * A wrapper for the Products API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class ItemsComponent extends APIComponent {
    /**
     * Get all products.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1['reboxed/items'], location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }

    async getForProduct(slug="", limit = 0) {
        const url = this.genURL(this.config.urls.api.v1['reboxed/items'] + '?product=' + slug, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get a reboxed item and its details.
     * @param {string} uuid The UUID for the item.
     */
    async getDetail(uuid = "") {
        const url = this.genURL(this.config.urls.api.v1['reboxed/items'] + uuid, location.origin, 0);

        let response = await this.sendRequest(url);
        return response.json();
    }

}

module.exports = ItemsComponent;
