const APIComponent = require('./api_component');

/**
 * A wrapper for the Collections API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class CollectionsComponent extends APIComponent {
    /**
     * Get collections.
     * @param {string} limit Optional limit for returned objects.
     */
    async get(limit = 0) {
        const url = this.genURL(this.config.urls.api.v1.collections, location.origin, limit);

        let response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Get a collection and its products.
     * @param {string} slug The slug for the collection.
     * @param {int} page The page number for product iteration.
     */
    async getDetail(slug = "", page = 1) {
        const url = this.genURL(this.config.urls.api.v1.collections + slug,
                                location.origin,
                                0,
                        {'page': page});

        let response = await this.sendRequest(url);
        return response.json();
    }
}

module.exports = CollectionsComponent;
