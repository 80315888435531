const CartComponent = require('./cart');
const RecentSalesComponent = require('./recent_sales');
const BrandGroupsComponent = require('./brandgroups');
const BrandsComponent = require('./brands');
const CatalogueComponent = require('./catalogue');
const CollectionsComponent = require('./collections');
const DiscountsComponent = require('./discounts');
const FreightComponent = require('./freight');
const ProductsComponent = require('./products');
const OrdersComponent = require('./orders');
const ItemsComponent = require('./reboxed_items');

/**
 * A wrapper for the DealerNet API.
 */
class Client {
    constructor(config, csrfToken) {
        this.config = config;
        this.csrfToken = this.config.csrfToken;

        this.cart = new CartComponent(this);
        this.brandgroups = new BrandGroupsComponent(this);
        this.brands = new BrandsComponent(this);
        this.recentSales = new RecentSalesComponent(this);
        this.catalogue = new CatalogueComponent(this);
        this.collections = new CollectionsComponent(this);
        this.discounts = new DiscountsComponent(this);
        this.freight = new FreightComponent(this);
        this.products = new ProductsComponent(this);
        this.orders = new OrdersComponent(this);
        this.reboxedItems = new ItemsComponent(this);
    }
}

module.exports = Client;
