const APIComponent = require('./api_component');

/**
 * A wrapper for the Catalogues API endpoint of DealerNet.
 * @extends {APIComponent}
 */
class CatalogueComponent extends APIComponent {
    /**
     * Get catalogue (categories, subcategories and products).
     * @param {string} categorySlug Optional category to use as root
     * @param {string} brand Optional brand filter
     * @param {number} limit Optional limit for returned objects.
     */
    async get(categorySlug = '', brand = null, limit = 0) {
        const params = { brand: brand };
        const url = this.genURL([this.config.urls.api.v1.catalogue, categorySlug], location.origin, limit, params);

        const response = await this.sendRequest(url);
        return response.json();
    }

    /**
     * Gets the slug of a category from a catalogue page url
     * @param {string} url 
     */
    getSlugFromUrl(url) {
        const u = new URL(url);
        const path = u.pathname;
        const cataloguePath = this.config.urls.catalogue;
        const match = path.match(cataloguePath + '(.*)');
        if (match) {
            let slug = match[1];
            if (slug.slice(-1) === '/') {
                slug = slug.slice(0, -1);
            }
            return slug;
        }
    }
}

module.exports = CatalogueComponent;
