const path = require('path-browserify');

/**
 * A base component that defines wrapper methods for creating requests.
 */
class APIComponent {
    constructor(client) {
        this.config = client.config;
        this.csrfToken = client.csrfToken;
    }

    /**
     * Generates a dictionary of headers to send with API requests.
     * Automatically adds the CSRF token associated with the client.
     * @param {string} contentType The desired content type.
     */
    createHeaders(contentType = 'application/json') {
        var headers = {
            "X-CSRFToken": this.csrfToken,
        };

        if (contentType) {
            headers["Content-Type"] = contentType
        }

        return headers;
    }

    /**
     * Sends a request to the desired URL.
     * @param {string|URL} url The URL to send the request to.
     * @param {Object} data The body of the request.
     * @param {string} method The type of method to use for the request, such as GET, POST, PUT, etc.
     */
    async sendRequest(url = ``, data, method = 'GET') {
        var options = {
            method: method,
        };

        if (data) {
            options.headers = this.createHeaders();
            options.body = JSON.stringify(data);
        } else {
            options.headers = this.createHeaders(null);
        }

        const response = await fetch(url, options);
        return response;
    }

    /**
     * Wraps the URL interface, allowing for a limit to be added for list endpoints.
     * @param {string | string[]} url_string URL as a string, or URL parts as an array of strings.
     * @param {string} origin Origin of the URL.
     * @param {string} limit Optional limit used for list endpoints.
     * @param {object} params Optional query parameters.
     */
    genURL(url_string, origin, limit = 0, params = {}) {
        if (Array.isArray(url_string)) {
            url_string = path.join(...url_string);
        }

        const url = new URL(url_string, origin);
        if (limit > 0) {
            url.searchParams.append("limit", limit);
        }

        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, value);
        }

        return url;
    }
}

module.exports = APIComponent;
